import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import AccountContent from "../../components/AccountContent"
import { Link } from "gatsby"
import "./my-account.scss"
import { gql, useQuery } from "@apollo/client"
import { isUserLoggedIn } from "../../utils/function"

function EditAddress(props) {
        let location = typeof window !=="undefined"?window.location.hash:null;
    const USER_ADDRESS = gql`
    {
        customer(customerId: 389) {
        billing {
        address1
                address2
                city
                company
                country
                email
                firstName
                lastName
                state
                postcode
                phone
        }
        shipping {
        address1
                address2
                city
                company
                country
                email
                firstName
                lastName
                state
                postcode
                phone
        }
        }
    }
    `
    const auth =isUserLoggedIn()
    const customerId= auth!==null?auth.userID:null
    const [userAddress, setUserAddress] = useState([])
    const { error, loading, data, refetch } = useQuery(USER_ADDRESS, {
        variables: { customerId: customerId }
    })

    useEffect(() => {
        if (auth) {
          refetch()
        }
      }, [auth])
    
      useEffect(() => {
        if (data) {
          setUserAddress(data)
        }
      }, [data])
      
    return (
        <Layout>
            <div className="section my-account">
                <div className="container pd-row">
                    <AccountContent>
                        <div className="my-account__edit">
                            <form className="login__form">
                                {
                                    location == "#billing" ?
                                        <h3>Billing Address</h3> :
                                        <h3>Shipping Address</h3>
                                }
                                <div className="input-wrapper full-mb half">
                                    <label htmlFor="">
                                        First name
                                        <abbr title="required">*</abbr>
                                    </label>
                                    <input type="text" name="" id="" required 
                                    value={loading ? "loading..." : (location == "#billing" ? 
                                    (userAddress?.customer?.billing?.firstName ? userAddress?.customer?.billing?.firstName : "") : 
                                    (userAddress?.customer?.shipping?.firstName ? userAddress?.customer?.shipping?.firstName : ""))}/>
                                </div>
                                <div className="input-wrapper full-mb half">
                                    <label htmlFor="">
                                        Last name
                                        <abbr title="required">*</abbr>
                                    </label>
                                    <input type="text" name="" id="" required 
                                    value={loading ? "loading..." : (location == "#billing" ? 
                                    (userAddress?.customer?.billing?.lastName ? userAddress?.customer?.shipping?.firstName : "") :
                                    (userAddress?.customer?.shipping?.lastName ? userAddress?.customer?.shipping?.lastName : ""))}/>
                                </div>
                                <div className="input-wrapper full-mb">
                                    <label htmlFor="">
                                        Company name (optional)
                                    </label>
                                    <input type="text" name="" id="" required 
                                    value={loading ? "loading..." : (location == "#billing" ? 
                                    (userAddress?.customer?.billing?.company ? userAddress?.customer?.billing?.company : "") : 
                                    (userAddress?.customer?.shipping?.company ? userAddress?.customer?.shipping?.company : ""))}/>
                                </div>
                                <div className="input-wrapper full-mb">
                                    <label htmlFor="">
                                        Country/Region
                                        <abbr title="required">*</abbr>
                                    </label>
                                    <span>
                                     {loading ? "loading..." : (location == "#billing" ? 
                                     (userAddress?.customer?.billing?.country ? userAddress?.customer?.billing?.country : "") : 
                                     (userAddress?.customer?.shipping?.country ? userAddress?.customer?.shipping?.country : ""))}
                                    </span>
                                </div>
                                <div className="input-wrapper full-mb">
                                    <label htmlFor="">
                                        Street address
                                        <abbr title="required">*</abbr>
                                    </label>
                                    <input type="text" name="" id="" required 
                                    value={loading ? "loading..." : (location == "#billing" ? 
                                    (userAddress?.customer ? userAddress?.customer?.billing?.address1 : "") : 
                                    (userAddress?.customer?.shipping?.address1 ? userAddress?.customer?.shipping?.address1 : ""))}/><br/>
                                    <input type="text" name="" id="" required />
                                </div>
                                <fieldset>

                                    <div className="input-wrapper full-mb">
                                        <label htmlFor="">
                                            Suburb
                                            <abbr title="required">*</abbr>
                                        </label>
                                        <input type="text" name="" id="" 
                                        value={loading ? "loading..." : (location == "#billing" ? 
                                        (userAddress?.customer?.billing?.city ? userAddress?.customer?.billing?.city : "") :
                                        (userAddress?.customer?.shipping?.city ? userAddress?.customer?.shipping?.city : ""))}/>
                                    </div>
                                    <div className="input-wrapper full-mb">
                                        <label htmlFor="">
                                            State
                                            <abbr title="required">*</abbr>
                                        </label>
                                        <select>
                                            <option>nghia</option>
                                        </select>
                                    </div>
                                    <div className="input-wrapper full-mb">
                                        <label htmlFor="">
                                            Postcode
                                            <abbr title="required">*</abbr>
                                        </label>
                                        <input type="text" name="" id="" 
                                        value={loading ? "loading..." : (location == "#billing" ? 
                                        (userAddress?.customer?.billing?.postcode ? userAddress?.customer?.billing?.postcode : "") : 
                                        (userAddress?.customer?.shipping?.postcode ? userAddress?.customer?.shipping?.postcode : ""))}/>
                                    </div>
                                    <div className="input-wrapper full-mb">
                                        <label htmlFor="">
                                            Phone
                                            <abbr title="required">*</abbr>
                                        </label>
                                        <input type="text" name="" id="" 
                                        value={loading ? "loading..." :(location == "#billing" ? 
                                        (userAddress?.customer?.billing?.phone ? userAddress?.customer?.billing?.phone : "") : 
                                        (userAddress?.customer?.shipping?.phone ? userAddress?.customer?.shipping?.phone : ""))}/>
                                    </div>
                                    <div className="input-wrapper full-mb">
                                        <label htmlFor="">
                                            Email address
                                            <abbr title="required">*</abbr>
                                        </label>
                                        <input type="text" name="" id="" 
                                        value={loading ? "loading..." : (location == "#billing" ? 
                                        (userAddress?.customer?.billing?.email ? userAddress?.customer?.billing?.email : "") : 
                                        (userAddress?.customer?.shipping?.email ? userAddress?.customer?.shipping?.email : ""))}/>
                                    </div>
                                </fieldset>
                                <div className="input-wrapper input-wrapper--submit">
                                    <button type="submit" value="Save changes">Save changes</button>
                                </div>
                            </form>
                        </div>
                    </AccountContent>
                </div>
            </div>
        </Layout>
    )
}

export default EditAddress